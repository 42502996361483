import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Slide,
  Dialog,
  DialogContent,
  DialogActions,
  Avatar,
  Hidden,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import { BiBell } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { AiFillCaretDown } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import React, { useState, useContext, useEffect } from "react";

import Logo from "./../../component/Logo";
import ConnectModal from "src/component/ConnectModal";
import SettingsContext from "src/context/SettingsContext";
import AnnouncementCard from "src/component/AnnouncementCard";

const headersData = [
  {
    label: "About Us",
    href: "/live-and-upcomming",
  },
  {
    label: "Contact Us",
    href: "/learn-more",
  },
];
const announcement = [{}, {}, {}];

const useStyles = makeStyles((theme) => ({
  menubox: {
    "& ul": {
      width: "136px",
      borderRadius: "5px",
      backgroundColor: "#212121 !important",
      background: "#110720",
      "& a": {
        fontSize: "15px",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "500",
        color: "#B7B7B7",
        "&.active": {
          color: "#fff",
        },
        "&:hover": {
          color: "#fff",
        },
      },
    },
  },
  logoDrawer2: {
    paddingLeft: "10px",
    width: "300px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#FFFFFF",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      background:
        "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
      textShadow: "3px 3px 3px rgb(0 0 0 / 13%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "&:hover": {
      background:
        "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
      textShadow: "3px 3px 3px rgb(0 0 0 / 13%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },
  menuButton1: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "green",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  logoDrawerTop: {
    paddingLeft: "10px",
    width: "100px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
  logoDrawer3: {
    paddingLeft: "10px",
    width: "100px",
    marginTop: "-40px",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    // alignItems: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
    display: "flex",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "14px !important",
    fontWeight: "400",
    fontStyle: "normal",
    // letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    // borderBottom: "1px solid #3e3e3e",
    padding: "10px",
    "@media (max-width: 600px)": {
      width: "100%",
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      background: "rgba(255, 255, 255, 0.05)",
      borderRadius: "10px",
      padding: "16px 0px",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  drawerPaper: {
    width: "100%",
    height: "100%",
  },
  IconButtonBox: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  sidebarBox: {
    maxWidth: "400px",
    width: "100%",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "355px",
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open11, setOpen11] = React.useState(false);

  const handleDrawerOpen11 = () => {
    setOpen11(true);
  };

  const handleDrawerClose11 = () => {
    setOpen11(false);
  };
  const classes = useStyles();
  const StyledMenu = withStyles({
    paper: {
      // border: "1px solid #d3d4d5",
      marginTop: "20px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    menubox,
    drawerContainer,
    drawericon,
    logoDrawer,
    logoDrawer2,
    sidebarBox,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  // console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [open, setOpen] = React.useState(false);
  // const [checkout, setCheckout] = React.useState(false);
  //   const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleOpen = () => {
    setOpen(true);
  };
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  // const [open1, setOpen1] = useState({ community: false, user: false });
  // const anchorRef = { community: useRef(null), user: useRef(null) };

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  const [disconnect, setDisconnect] = useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleOpendisconnect = () => {
    setDisconnect(true);
    setAnchorEl(null);
  };
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {/* <div className={search}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search items, collections, creators"
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div> */}
            {getMenuButtons()}

            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              className={menubox}
              onClose={handleClose1}
            >
              <MenuItem
                component={Link}
                onClick={() => history.push("/setting")}
              >
                Profile
              </MenuItem>
              <MenuItem component={Link} onClick={handleDrawerOpen11}>
                Announcements
              </MenuItem>
              <MenuItem component={Link} onClick={handleOpendisconnect}>
                Disconnect
              </MenuItem>
            </StyledMenu>
            {/* <Box mb={1}>
              {themeSeeting?.settings?.theme === "DARK" ? (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("LIGHT");
                  }}
                >
                  <FiSun />
                </IconButton>
              ) : (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    changeTheme("DARK");
                  }}
                >
                  <FaRegMoon />
                </IconButton>
              )}
            </Box> */}

            {window.location.pathname === "/" ? (
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#FFAC1C",
                  padding: 12,
                  width: "13%",
                  borderRadius: 30,
                  fontFamily: "unset",
                  color: "white",
                  marginLeft: 17,
                }}
                onClick={() => history.push("/login")}
              >
                Login
              </Button>
            ) : (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   style={{ marginLeft: "17px" }}
              //   onClick={() => history.push("/login")}
              //   //  onClick={handleOpen}
              // >
              //   Login
              // </Button>
              <>
                <Box ml={2}>
                  <IconButton
                    style={{ backgroundColor: "#313131", borderRadius: "10px" }}
                    onClick={() => history.push("/notifications")}
                  >
                    <BiBell />
                  </IconButton>
                </Box>

                <Box
                  ml={2}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleClick}
                >
                  <Avatar src="images/mgpro.png" onClick={handleClick} />
                  <AiFillCaretDown
                    style={{ color: "#fff", marginLeft: "5px" }}
                  />
                </Box>
              </>
            )}

            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              className={menubox}
              onClose={handleClose1}
            >
              <MenuItem
                component={Link}
                onClick={() => history.push("/setting")}
              >
                Profile
              </MenuItem>
              <MenuItem component={Link} onClick={handleDrawerOpen11}>
                Announcements
              </MenuItem>
              <MenuItem component={Link} onClick={handleOpendisconnect}>
                Disconnect
              </MenuItem>
            </StyledMenu>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Hidden xsDown>
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>
              <img
                className={logoDrawer2}
                alt="logo"
                src={require("../../assets/images/SplashLogo.png")}
              />
              {getDrawerChoices()}
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#FFAC1C",
                  // padding: 20,
                  width: "25%",
                  borderRadius: 30,
                  fontFamily: "unset",
                  color: "white",
                  marginTop: 15,
                }}
                onClick={() => history.push("/login")}
              >
                Login
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/login")}
                style={{
                  marginLeft: "19px",
                  padding: "10px 23px",
                  marginTop: "15px",
                }}
              >
                Login
              </Button> */}
            </div>
          </Drawer>
        </Hidden>
        <Hidden smUp>
          <SwipeableDrawer
            {...{
              anchor: "bottom",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={drawerContainer}>
              <div className={classes.IconButtonBox}>
                <IconButton onClick={() => handleDrawerClose()}>
                  <RxCross2 />
                </IconButton>
              </div>
              <img
                // className={logoDrawer2}
                alt="logo"
                className={classes.logoDrawer3}
                src={require("../../assets/images/SplashLogo.png")}
              />
              {/* <img className={logoDrawer} src="/images/logo.png" alt="" /> */}
              {getDrawerChoices()}
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/login")}
                style={{
                  // marginLeft: "19px",
                  padding: "10px 23px",
                  marginTop: "25px",
                }}
              >
                Login
              </Button>
            </div>
          </SwipeableDrawer>
        </Hidden>

        <div>{femmecubatorLogo}</div>

        {/* ****** Dropdown Icon ****** */}
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <Box className={drawericon}>
              {/* <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleClick}
                mr={1}
              >
                <Avatar src="images/mgpro.png" />
                <AiFillCaretDown style={{ color: "#fff", fontSize: "15px" }} />
              </Box> */}
              <IconButton
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#ff2676", fontSize: "30px" }}
                />
              </IconButton>
              {/* <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                className={menubox}
                onClose={handleClose1}
              >
                <MenuItem component={Link} to="/profile">
                  Profile
                </MenuItem>
                <MenuItem component={Link} onClick={handleDrawerOpen11}>
                  Announcements===
                </MenuItem>
                <MenuItem component={Link} onClick={handleOpendisconnect}>
                  Disconnect
                </MenuItem>
              </StyledMenu> */}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          {/* <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button> */}

          <NavLink
            exact
            // to={`${href}`}
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
              activeClassName: "active",
            }}
          >
            {" "}
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ background: "transparent", border: "none" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={open11}
        onClose={handleDrawerClose11}
        style={{ overflowY: "scroll" }}
      >
        <Box className={sidebarBox} style={{}}>
          <Box>
            <IconButton
              style={{ position: "absolute", top: "0px", right: "5px" }}
              onClick={handleDrawerClose11}
            >
              <IoClose style={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box align="center" py={2}>
            <Typography variant="h4">Announcements</Typography>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              {announcement.map(() => (
                <AnnouncementCard />
              ))}
            </Grid>
          </Grid>
          <Box align="right">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10px 0px" }}
              onClick={() => history.push("/view-all-announcement")}
            >
              View More
            </Button>
          </Box>
        </Box>
      </Drawer>
      <ConnectModal setOpen={setOpen} open={open} />
      <Dialog
        maxWidth="sm"
        fullWidth
        className={classes.dailogOpen}
        open={disconnect}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDisconnect(false)}
      >
        <Box className="newModalBorder">
          <Box className="mainbox1">
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography
                  variant="h2"
                  style={{
                    fontFamily: "Sora, sans-serif",
                    fontWeight: "300",
                    marginBottom: "16px",
                  }}
                >
                  Dissconnect Wallet
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontFamily: "'Sora', sans-serif" }}
                >
                  Are you sure want to Dissconnect?==
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  className="transparentbutton"
                  style={{ padding: "8px 25px" }}
                  // onClick={() => setIsLogout(false)}
                >
                  Confirm
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="outlined"
                  style={{ padding: "8px 25px" }}
                  variant="contained"
                  color="secondary"
                  onClick={() => setDisconnect(false)}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
