import { clearLoginDetails } from "./auth";
import { clearBalanceHistoryList } from "./balanceHistory";
import { clearsetCalculatorsDetails } from "./calculators";
import { clearDealerMisHistory } from "./dealerMis/dealerMis";
import { clearInsuranceDetails } from "./insurance";
import { clearPreviewPolicyList } from "./policy";
import { clearSavedPolicyId } from "./savePolicy";
import { clearUserBalance } from "./wallet";

export const CLEAR_REDUX_STATE = {
  CLEAR_LOGIN_DETAILS: clearLoginDetails,
  CLEAR_BALANCE_HISTORY: clearBalanceHistoryList,
  CLEAR_CALCULATOR_DETAILS: clearsetCalculatorsDetails,
  CLEAR_DEALER_MIS_HISTORY: clearDealerMisHistory,
  CLEAR_INSURANCE_DETAILS: clearInsuranceDetails,
  CLEAR_PREVIEW_POLICY_LIST: clearPreviewPolicyList,
  CLEAR_SAVED_POLICY_ID: clearSavedPolicyId,
  CLEAR_USER_BALANCE: clearUserBalance,
};
