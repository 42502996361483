import {
  Box,
  Grid,
  Menu,
  Slide,
  AppBar,
  Hidden,
  Dialog,
  Avatar,
  Button,
  SvgIcon,
  Toolbar,
  MenuItem,
  makeStyles,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Menu as MenuIcon } from "react-feather";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ExitToApp, LockOpenOutlined } from "@material-ui/icons";

import { AuthContext } from "src/context/Auth";
import HomeTopBarLogo from "../../../component/HomeTopBarLogo";
import { CLEAR_REDUX_STATE } from "../../../redux/CommonRedux";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: "#f5f5f5",
    // padding: "7px 30px 7px 30px", // Comment to Reduce Height
    margin: "14px 41px 0 16px", // Comment to Remove Margin
    borderRadius: "10px",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    // background: theme.palette.background.header,
    [theme.breakpoints.down("md")]: {},
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& svg": {
      color: theme.palette.text.primary,
    },
    "& .leftBox": {
      width: "306px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        width: "150px",
        [theme.breakpoints.down("xs")]: {
          width: "104px",
          paddingLeft: "0 !important",
        },
      },
    },
  },
  menuPaper: {
    backgroundColor: "white",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  // ****** Redux ******
  const AuthType = useSelector((state) => state?.auth?.type);
  const userName = useSelector((state) => state?.auth?.userName);
  const remainingBalance = useSelector((state) => state?.wallet?.amount);

  // Logout Func
  const [isLogout, setIsLogout] = useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  // ****** Logout Func ******
  const onLogout = async () => {
    dispatch(CLEAR_REDUX_STATE.CLEAR_LOGIN_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_BALANCE_HISTORY());
    dispatch(CLEAR_REDUX_STATE.CLEAR_CALCULATOR_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_DEALER_MIS_HISTORY());
    dispatch(CLEAR_REDUX_STATE.CLEAR_INSURANCE_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_PREVIEW_POLICY_LIST());
    dispatch(CLEAR_REDUX_STATE.CLEAR_SAVED_POLICY_ID());
    dispatch(CLEAR_REDUX_STATE.CLEAR_USER_BALANCE());
    toast.success("Logout successful!");
    history.push("/login");
  };

  // const [open11, setOpen11] = React.useState(false);
  // const [disconnect, setDisconnect] = useState(false);
  // const StyledMenu = withStyles({
  //   paper: {
  //     // border: "1px solid #d3d4d5",
  //     marginTop: "20px",
  //   },
  // })((props) => (
  //   <Menu
  //     elevation={0}
  //     getContentAnchorEl={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "center",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "center",
  //     }}
  //     {...props}
  //   />
  // ));

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const themeSeeting = useContext(SettingsContext);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose1 = () => {
  //   setAnchorEl(null);
  // };
  // const changeTheme = (type) => {
  //   themeSeeting.saveSettings({
  //     theme: type,
  //   });
  // };
  // const handleDrawerOpen11 = () => {
  //   setOpen11(true);
  // };
  // const handleOpendisconnect = () => {
  //   setDisconnect(true);
  //   setAnchorEl(null);
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      style={{
        boxShadow: "none",
        background: "transparent",
      }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton onClick={onMobileNavOpen} style={{ padding: "0px" }}>
            <SvgIcon>
              <MenuIcon style={{ color: "#FF0098", fontSize: "25px" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        &nbsp; &nbsp;
        <Box className={classes.mainheader}>
          <Grid item container alignItems="center">
            {/* ****** Logo & Balance Container ****** */}
            {AuthType === "Dealer" ? (
              <Grid item lg={3} md={3} sm={4} xs={6}>
                <Box className="leftBox">
                  <IconButton style={{ borderRadius: "10px" }}>
                    <Typography style={{ color: "black", fontSize: 15 }}>
                      Balance:{" "}
                      <Typography variant="" style={{ color: "black" }}>
                        ₹{" "}
                        {remainingBalance
                          ? JSON.stringify(remainingBalance)
                          : // remainingBalance.toFixed(2)
                            "0"}
                      </Typography>
                    </Typography>
                  </IconButton>
                  <Link to="/">{/* <HomeTopBarLogo /> */}</Link>
                </Box>
              </Grid>
            ) : (
              <Grid item lg={3} md={3} sm={4} xs={6}>
                <Box className="leftBox">
                  <HomeTopBarLogo />
                </Box>
              </Grid>
            )}

            {/* ****** Search Box Container ****** */}
            {/* <Hidden xsDown> 
             <Grid item lg={2} md={2} sm={2}>
              <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Please enter Password"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ paddingLeft: "3px" }}
                      >
                        <FiSearch style={{ fontSize: "20px" }} />
                      </InputAdornment>
                    ),
                  }}
                /> 
            </Grid>
            {/* </Hidden> */}

            {/* ****** Bell & Profile Container ****** */}
            <Grid item lg={9} md={9} sm={8} xs={6}>
              <Box className="displayEnd">
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography
                    style={{
                      color: "black",
                      fontSize: 18,
                      marginBottom: -15,
                      fontWeight: "500",
                    }}
                  >
                    {userName ? userName : "NA"}
                  </Typography>
                  <IconButton style={{ borderRadius: "10px" }}>
                    <Typography style={{ color: "black", fontSize: 15 }}>
                      {AuthType === "Admin" ? "Admin" : "Dealer"}
                    </Typography>
                  </IconButton>
                </Box>

                {/* ****** Change & Logout PopUp Container ****** */}
                <Box
                  ml={2}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {/* ****** Profile Icon Container ****** */}
                  <IconButton
                    style={{ borderRadius: "10px" }}
                    onClick={handleUserClick}
                  >
                    <img
                      height={40}
                      src={require("../../../assets/images/Profile.png")}
                      alt=""
                    />
                  </IconButton>

                  {/* ****** Change Password & Logout Menu ****** */}
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleUserClose}
                    classes={{ paper: classes.menuPaper }}
                  >
                    <MenuItem
                      style={{ color: "black" }}
                      onClick={() => {
                        handleUserClose();
                        history.push("/changepassword");
                      }}
                    >
                      <LockOpenOutlined
                        style={{ width: 20, marginRight: 10 }}
                      />
                      Change Password
                    </MenuItem>
                    <MenuItem
                      style={{ color: "black" }}
                      onClick={() => {
                        handleUserClose();
                        setIsLogout(true);
                      }}
                    >
                      <ExitToApp style={{ width: 20, marginRight: 10 }} />
                      Logout
                    </MenuItem>
                  </Menu>

                  {/* ****** Logout PopUp Container ****** */}
                  {isLogout && (
                    <Dialog
                      maxWidth="sm"
                      fullWidth
                      className={classes.dailogOpen}
                      open={isLogout}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={() => setIsLogout(false)}
                    >
                      <Box className="newModalBorder">
                        <Box
                          style={{ backgroundColor: "white" }}
                          className="mainbox1"
                        >
                          <DialogContent>
                            <Box
                              className={classes.dialougTitle}
                              align="center"
                            >
                              <Typography
                                variant="h2"
                                style={{
                                  fontFamily: "Sora, sans-serif",
                                  fontWeight: "300",
                                  marginBottom: "16px",
                                  color: "black",
                                }}
                              >
                                Logout
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  fontFamily: "'Sora', sans-serif",
                                  color: "black",
                                }}
                              >
                                Are you sure, you want to logout?
                              </Typography>
                            </Box>
                          </DialogContent>
                          <DialogActions
                            style={{
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box mt={2} mb={2} maxWidth="400px">
                              <Button
                                type="submit"
                                variant="contained"
                                style={{
                                  backgroundColor: "#FFAC1C",
                                  padding: 12,
                                  width: 120,
                                  borderRadius: 30,
                                  fontFamily: "unset",
                                  color: "white",
                                }}
                                onClick={() => onLogout()}
                              >
                                Confirm
                              </Button>
                              {/* <Button
                                variant="contained"
                                color="primary"
                                className="transparentbutton"
                                style={{ padding: "8px 25px" }}
                                onClick={() => {
                                  setIsLogout(false);
                                  toast.success("Logout successful!");
                                  history.push("/login");
                                }}
                              >
                                Confirm
                              </Button> */}
                              &nbsp; &nbsp;
                              <Button
                                type="submit"
                                variant="contained"
                                style={{
                                  backgroundColor: "white",
                                  padding: 12,
                                  width: 120,
                                  borderRadius: 30,
                                  fontFamily: "unset",
                                  color: "black",
                                }}
                                onClick={() => setIsLogout(false)}
                              >
                                Cancel
                              </Button>
                              {/* <Button
                                className="outlined"
                                style={{ padding: "8px 25px" }}
                                variant="contained"
                                color="secondary"
                                onClick={() => setIsLogout(false)}
                              >
                                Cancel
                              </Button> */}
                            </Box>
                          </DialogActions>
                        </Box>
                      </Box>
                    </Dialog>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
          // onClick={() => history.push("/admin-profile")}
        />
      </Box>
    </>
  );
}
