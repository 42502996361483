import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    token: "",
    type: "",
    userId: "",
    userName: "",
  },
  reducers: {
    setLoginDetails: (state, action) => {
      state.isLoggedIn = action?.payload?.isLoggedIn;
      state.token = action?.payload?.token;
      state.type = action?.payload?.type;
      state.userId = action?.payload?.userId;
      state.userName = action?.payload?.userName;
    },
    clearLoginDetails: (state) => {
      state.isLoggedIn = false;
      state.token = "";
      state.type = "";
      state.userId = "";
      state.userName = "";
    },
  },
});

export const { setLoginDetails, clearLoginDetails } = authSlice.actions;
export default authSlice.reducer;
