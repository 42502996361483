import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "./layouts/LoginLayout/inedx";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    // component: lazy(() => import("src/views/pages/Home")),
    component: lazy(() => import("./views/user/pages/Home")),
  },

  {
    exact: true,
    path: "/createPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/user/pages/insurance/createPolicy/createPolicy")
    ),
  },

  {
    exact: true,
    path: "/viewPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/viewPolicy/viewPolicy")
    ),
  },
  {
    exact: true,
    path: "/editPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/editPolicy/editPolicy")
    ),
  },

  {
    exact: true,
    path: "/addBalance",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/addBalance/addBalance")),
  },

  {
    exact: true,
    path: "/changePassword",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/auth/changepassword")),
  },

  {
    exact: true,
    path: "/dealerMis",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/user/pages/dealerMis/dealerMis")),
  },

  {
    exact: true,
    path: "/previewPolicy",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/user/pages/insurance/previewPolicy/previewPolicy")
    ),
  },

  // Admin Routes Starts
  {
    exact: true,
    path: "/admin-dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/dashboard")),
  },
  {
    exact: true,
    path: "/admin-create-dealer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/dealer/createDealer")),
  },
  {
    exact: true,
    path: "/admin-viewedit-dealer",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/dealer/viewEditDealer")),
  },
  {
    exact: true,
    path: "/admin-create-vehicle",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/vehicle/createVehicle")),
  },
  {
    exact: true,
    path: "/admin-viewedit-vehicle",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/vehicle/viewEditVehicle")),
  },
  {
    exact: true,
    path: "/admin-report-analytics",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/reportAnalytics")),
  },
  {
    exact: true,
    path: "/admin-plan",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/plan")),
  },
  {
    exact: true,
    path: "/admin-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/wallet")),
  },
  {
    exact: true,
    path: "/admin-profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/user/admin/profile")),
  },
  // Admin Routes Ends

  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/login")),
  },

  {
    exact: true,
    path: "/forgotPassword",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/user/pages/auth/forgotPassword/forgotPassword")
    ),
  },

  {
    exact: true,
    path: "/otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/otp/otp")),
  },

  {
    exact: true,
    path: "/reset",
    layout: LoginLayout,
    component: lazy(() => import("src/views/user/pages/auth/reset/reset")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/user/errors/NotFound")),
  },

  {
    exact: true,
    path: "/ComingSoon",
    component: lazy(() => import("src/views/user/errors/ComingSoon")),
  },

  {
    component: () => <Redirect to="/404" />,
  },

  {
    component: () => <Redirect to="/ComingSoon" />,
  },

  // ****** Admin Routes ******
  // {
  //   exact: true,
  //   path: "/approval",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("./views/user/pages/approval")),
  // },
  // {
  //   path: "*",
  //   routes: [
  //     {
  //       exact: true,
  //       path: "/admin-create-dealer",
  //       component: lazy(() =>
  //         import("src/views/admin/src/views/pages/dealer/createDealer")
  //       ),
  //     },
  //   ],
  // },
];
